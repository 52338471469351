import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Date } from 'prismic-reactjs'
import styled from "styled-components"

const StyledDiv2 = styled.div`
  background-image: linear-gradient(to bottom, #679898, #f0f5f5);
`

const h2Style = "text-xl md:text-2xl mb-4 font-extrabold md:line-clamp-2"
const pStyle = "text-base line-clamp-3 mb-14"
const dateStyle = "text-md mb-6"

const TopBlogPosts = () => {
  const data = useStaticQuery(graphql`
    query TopBlogPostsQuery {
      prismicBlogListPage {
        data {
          first_top_blog_post_slug
          second_top_blog_post_slug
          third_top_blog_post_slug
        }
      }
      allPrismicBlogPost {
        edges {
          node {
            id
            uid
            data {
              page_title
              post_excerpt
              date_published
              page_hero_background {
                alt
                gatsbyImageData(
                  width: 1920
                )
              }
            }
          }
        }
      }
    }
  `)

  const topBlogPost = data.prismicBlogListPage
  const blogPosts = data.allPrismicBlogPost.edges

  return (
    <StyledDiv2 className="py-20">

      <div className="w-11/12 md:w-8/12 xl:w-5/12 mx-auto border-b-2 border-site-gray-dark pb-5">

        <h2 className="text-white text-2xl md:text-4xl uppercase font-extrabold text-center tracking-wider">
          Featured Posts
        </h2>

      </div>

      <div className="relative w-11/12 lg:w-10/12 max-w-screen-xl grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 mx-auto mt-16">

        {blogPosts.map(item => {
          const blogImage = getImage(item.node.data.page_hero_background)
          const datePosted = Date(item.node.data.date_published)
          const formattedDate = new Intl.DateTimeFormat('en-US',{
            year: "numeric",
            month: "short",
            day: "numeric"
          }).format(datePosted);

          return (
            <>
              {topBlogPost.data.first_top_blog_post_slug === item.node.uid
              ?
              <div className="relative bg-white border border-gray-400 hadow-lg">

                <div>
                  <Link to={`/blog/${item.node.uid}`}>
                    <GatsbyImage
                      image={blogImage}
                      alt="blog image"
                    />
                  </Link>
                </div>

                <div className="p-8">

                  <p className={dateStyle}>{formattedDate}</p>
                  <Link to={`/blog/${item.node.uid}`}>
                    {item.node.data.page_title.length >= 50
                      ?
                      <div className="tooltip relative">
                        <h2 className={h2Style}>{item.node.data.page_title}</h2>
                        <span className="tooltiptext">{item.node.data.page_title}</span>
                      </div>
                      :
                      <h2 className={h2Style}>{item.node.data.page_title}</h2>
                    }
                  </Link>

                  <p className={pStyle}>
                    {item.node.data.post_excerpt}
                  </p>

                  <Link to={`/blog/${item.node.uid}`}>
                    <button className="absolute bottom-0 left-0 right-0 w-full bg-site-red hover:bg-site-red-hover text-sm text-white tracking-wider mt-6 p-3">
                      Read More
                    </button>
                  </Link>

                </div>

              </div>
              :
              null}

              {topBlogPost.data.second_top_blog_post_slug === item.node.uid
              ?
              <div className="relative bg-white border border-gray-400 shadow-lg">

                <div>
                  <Link to={`/blog/${item.node.uid}`}>
                    <GatsbyImage
                      image={blogImage}
                      alt="blog image"
                    />
                  </Link>
                </div>

                <div className="p-8">

                  <p className={dateStyle}>{formattedDate}</p>
                  <Link to={`/blog/${item.node.uid}`}>
                    {item.node.data.page_title.length >= 50
                      ?
                      <div className="tooltip relative">
                        <h2 className={h2Style}>{item.node.data.page_title}</h2>
                        <span className="tooltiptext">{item.node.data.page_title}</span>
                      </div>
                      :
                      <h2 className={h2Style}>{item.node.data.page_title}</h2>
                    }
                  </Link>

                  <p className={pStyle}>
                    {item.node.data.post_excerpt}
                  </p>

                  <Link to={`/blog/${item.node.uid}`}>
                    <button className="absolute bottom-0 left-0 right-0 w-full bg-site-red hover:bg-site-red-hover text-sm text-white tracking-wider mt-6 p-3">
                      Read More
                    </button>
                  </Link>

                </div>

              </div>
              :
              null}

            {topBlogPost.data.third_top_blog_post_slug === item.node.uid
              ?
              <div className="relative bg-white border border-gray-400 shadow-lg">

                <div>
                  <Link to={`/blog/${item.node.uid}`}>
                    <GatsbyImage
                      image={blogImage}
                      alt="blog image"
                    />
                  </Link>
                </div>

                <div className="p-8">

                  <p className={dateStyle}>{formattedDate}</p>
                  <Link to={`/blog/${item.node.uid}`}>
                    {item.node.data.page_title.length >= 50
                      ?
                      <div className="tooltip relative">
                        <h2 className={h2Style}>{item.node.data.page_title}</h2>
                        <span className="tooltiptext">{item.node.data.page_title}</span>
                      </div>
                      :
                      <h2 className={h2Style}>{item.node.data.page_title}</h2>
                    }
                  </Link>

                  <p className={pStyle}>
                    {item.node.data.post_excerpt}
                  </p>

                  <Link to={`/blog/${item.node.uid}`}>
                    <button className="absolute bottom-0 left-0 right-0 w-full bg-site-red hover:bg-site-red-hover text-sm text-white tracking-wider mt-6 p-3">
                      Read More
                    </button>
                  </Link>

                </div>

              </div>
              :
              null}
            </>
          )
        })}
      </div>
    </StyledDiv2>
  )
}

export default TopBlogPosts
